let lastScrollTop = 0;
let wasScrollingDown = true;

const header = document.querySelector('header');

window.addEventListener('scroll', function() {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Якщо користувач проскролив менше 100px, просто виходимо з функції
    if (scrollTop < 100) {
        return;
    }

    let scrollingDown = scrollTop > lastScrollTop;

    if (scrollingDown && !wasScrollingDown) {
        header.classList.remove('animate__fadeInDown');
        header.classList.add('animate__fadeOutUp');
    } else if (!scrollingDown && wasScrollingDown) {
        header.classList.remove('animate__fadeOutUp');
        header.classList.add('animate__fadeInDown');
    }

    lastScrollTop = scrollTop;
    wasScrollingDown = scrollingDown;
});