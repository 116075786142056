const refs = {
  bodyEl: document.querySelector("body"),
  showMenuButton: document.querySelector(".show-menu-button"),
  hideMenuButton: document.querySelector(".hide-menu"),
  menuBackdropEl: document.getElementById("menu"),
  appointmentBackdrop: document.getElementById("appointment"),
  successfulBackdrop: document.getElementById("successful"),
  priceBackdrop: document.getElementById("price"),
  appointmentModal: document.querySelector(".appointment"),
  showAppointmentModalButtons: document.querySelectorAll(
    ".appointment-button-js",
  ),
  hideAppointmentModalButton: document.querySelector(".hide-appointment"),
  hideSuccessfulModalButton: document.querySelector(".hide-successful"),
  hidePriceModalButton: document.querySelector(".hide-price"),
  videos: document.querySelectorAll("video"),
  priceButton: document.querySelector(".price-button"),
  resultsBackdrop: $('#results-backdrop'),
  resultsList: $('.results-list'),
  resultsBackdropVanilla: document.getElementById('results-backdrop'),
  hideResultsBackdrop: document.querySelector('.results-hide-button')
};

export default refs;
