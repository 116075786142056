import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const gallerySwiper = new Swiper('.gallery-swiper', {
    slidesPerView: 1,
    effect: "coverflow",
    breakpoints: {
        576: {
            slidesPerView: 2,
            centeredSlides: true,
            loop: true,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 0,
                slideShadows: false,
            }
        },
        768: {
            slidesPerView: 3,
            loop: true,
            coverflowEffect: {
                rotate: 0,
                stretch: -50,
                depth: 119,
                modifier: 1,
                slideShadows: true,
            }
        },
        992: {
            slidesPerView: 3,
            loop: true,
            coverflowEffect: {
                rotate: 0,
                stretch: -75,
                depth: 119,
                modifier: 1,
                slideShadows: true,
            }
        },
        1440: {
            slidesPerView: 3,
            loop: true,
            coverflowEffect: {
                rotate: 0,
                stretch: -100,
                depth: 119,
                modifier: 1,
                slideShadows: true,
            }
        }
    },

    grabCursor: true,
    pagination: {
        el: ".gallery-swiper__pagination",
    },
    navigation: {
        prevEl: ".gallery .prev",
        nextEl: ".gallery .next"
    }
})

const devicesSwiper = new Swiper('.devices-swiper', {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    navigation: {
        nextEl: ".devices .next",
        prevEl: ".devices .prev",
    },
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        992: {
            centeredSlides: false,
            slidesPerView: 6,
            spaceBetween: 20
        },
        1440: {
            centeredSlides: false,
            slidesPerView: 6,
            spaceBetween: 70
        }
    },

    grabCursor: true,
    loop: true
})

const securitySwiper = new Swiper('.security-swiper', {
    slidesPerView: 1,
    grabCursor: true,
    navigation: {
        prevEl: ".security .prev",
        nextEl: ".security .next"
    },
    pagination: {
        el: ".security-swiper__pagination",
    },
    breakpoints: {
        576: {
            loop: true,
            slidesPerView: 2,
            centeredSlides: true,
        },
        768: {
            loop: true,
            centeredSlides: false,
            slidesPerView: 4,
            spaceBetween: 20,
        },
        1440: {
            loop: true,
            centeredSlides: false,
            slidesPerView: 4,
            spaceBetween: 50
        }
    }
})

const teamSwiper = new Swiper('.team-swiper', {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 1,
    coverflowEffect: {
        rotate: 0,
        stretch: -80,
        depth: 400,
        modifier: 1,
        slideShadows: true,
    },
    navigation: {
        nextEl: ".team .next",
        prevEl: ".team .prev",
    },
    breakpoints: {
        576: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 3
        },
        992: {
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 0,
                slideShadows: false,
            },
            slidesPerView: 7,
            spaceBetween: 20,
            centeredSlides: false
        },
        1440: {
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 0,
                slideShadows: false,
            },
            slidesPerView: 7,
            spaceBetween: 50,
            centeredSlides: false
        }
    },
    loop: true,
})

const resultsSwiper = new Swiper('.results-swiper', {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    grabCursor: true,
    navigation: {
        nextEl: ".results .next",
        prevEl: ".results .prev",
    },
    breakpoints: {
        992: {
            centeredSlides: false,
            slidesPerView: 6,
            spaceBetween: 20
        },
        1440: {
            centeredSlides: false,
            slidesPerView: 6,
            spaceBetween: 50
        }
    },
    loop: true,
    autoHeight: true,
})

const reviewsSwiper = new Swiper('.reviews-swiper', {
    slidesPerView: 1,
    spaceBetween: 20,
    grabCursor: true,
    centeredSlides: true,
    navigation: {
        nextEl: ".reviews .next",
        prevEl: ".reviews .prev",
    },

    loop: true,
    autoHeight: true,
    breakpoints: {
        768: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            centeredSlides: false,
            spaceBetween: 20,
            slidesPerView: 6
        },
        1440: {
            centeredSlides: false,
            slidesPerView: 6,
            spaceBetween: 50,
        }
    }
})

const priceDetailsSwiper = new Swiper('.price-details-swiper', {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
        nextEl: ".price-details-right",
        prevEl: ".price-details-left",
    },
    grabCursor: true,
    loop: true,
    autoHeight: true,
    keyboard: true,
})

export const resultsModalSwiper = new Swiper('.results-modal-swiper', {
    slidesPerView: 1,
    navigation: {
        nextEl: "#results-backdrop .next",
        prevEl: "#results-backdrop .prev",
    },
    keyboard: true,
    grabCursor: true,
    loop: true,
})

