import refs from "./refs";
import {showBackdrop, hideBackdrop} from "./utils";
import {resultsModalSwiper} from "./swiper"
import throttle from "lodash.throttle";

const {
    appointmentBackdrop,
    showAppointmentModalButtons,
    hideAppointmentModalButton,
    menuBackdropEl,
    successfulBackdrop,
    hideSuccessfulModalButton,
    hidePriceModalButton,
    appointmentModal,
    priceButton,
    priceBackdrop,
    resultsList,
    resultsBackdrop,
    resultsBackdropVanilla,
    hideResultsBackdrop
} = refs;

function setModalTheme(theme) {
    appointmentModal.classList.remove("white-theme", "black-theme");
    appointmentModal.classList.add(theme);
}

showAppointmentModalButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
        if (!menuBackdropEl.classList.contains("is-hidden")) {
            hideBackdrop(menuBackdropEl);
        }
        const currentButton = e.currentTarget;
        const {modalTheme = "black-theme"} = currentButton.dataset;
        setModalTheme(modalTheme);

        showBackdrop(appointmentBackdrop);
    });
});

hideAppointmentModalButton.addEventListener("click", () =>
    hideBackdrop(appointmentBackdrop),
);
hideSuccessfulModalButton.addEventListener("click", () =>
    hideBackdrop(successfulBackdrop),
);

hidePriceModalButton.addEventListener("click", () =>
    hideBackdrop(priceBackdrop),
);

hideResultsBackdrop.addEventListener("click", () =>
    hideBackdrop(resultsBackdropVanilla),
);

priceButton.addEventListener("click", () => showBackdrop(priceBackdrop));

document.addEventListener("wpcf7mailsent", () => {
    if (!appointmentBackdrop.classList.contains("is-hidden")) {
        hideBackdrop(appointmentBackdrop);
    }

    showBackdrop(successfulBackdrop);
});

const handleResultsResize = (e) => {
    const {innerWidth} = window;

    if (innerWidth < 992) {
        hideBackdrop(resultsBackdropVanilla);
        $(window).off("resize", throttledResultsResize);
    }
}

const throttledResultsResize = throttle(handleResultsResize, 300);

const handleResultClick = (e) => {
    if (e.target === e.currentTarget || window.innerWidth < 992) {
        return
    }

    const listItem = $(e.target).closest('.results-list__item');
    const imageSrc = listItem.find('img.d-lg-block').attr('src');
    const slideToShowIndex = resultsBackdrop.find(`img[data-src="${imageSrc}"]`).closest('.swiper-slide').attr('data-swiper-slide-index');

    resultsModalSwiper.slideTo(slideToShowIndex, 0);

    showBackdrop(resultsBackdropVanilla);

    $(window).on('resize', throttledResultsResize);
}

resultsList.on("click", handleResultClick);