import "waypoints/lib/noframework.waypoints.min";

const ctaThumbWaypoint = new Waypoint({
  element: document.querySelector(".cta-thumb"),
  handler: function () {
    this.element.classList.add("fade-in");
  },
  offset: "35%",
});

const aboutThumbWaypoint = new Waypoint({
  element: document.querySelector(".about-thumb"),
  handler: function () {
    if (!this.element.classList.contains("fade-in")) {
      this.element.classList.add("fade-in");
    }
  },
  offset: "70%",
});
