import refs from "./refs";
import throttle from "lodash.throttle";
import {WOW} from "wowjs/dist/wow.min";

const {bodyEl, videos} = refs;

const throttledHandleResize = throttle(handleResize, 200);

let currentBackdrop = null;

export const showBackdrop = (backdrop, hideOnResize = false) => {
    if (!backdrop) {
        return;
    }
    disableBodyScroll();

    currentBackdrop = backdrop;
    backdrop.classList.remove("is-hidden");
    backdrop.addEventListener("click", handleBackdropClick);
    window.addEventListener("keydown", handleKeyDown);

    if (hideOnResize) {
        window.addEventListener("resize", throttledHandleResize);
    }
};

export const hideBackdrop = (backdrop) => {
    if (!backdrop) {
        return;
    }

    enableBodyScroll();

    backdrop.classList.add("is-hidden");
    backdrop.removeEventListener("click", handleBackdropClick);
    window.removeEventListener("keydown", handleKeyDown);
    window.removeEventListener("resize", throttledHandleResize);

    currentBackdrop = null;
};

function handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
        hideBackdrop(currentBackdrop);
    }
}

function handleKeyDown(e) {
    if (e.code === "Escape") {
        hideBackdrop(currentBackdrop);
    }
}

function handleResize() {
    const {innerWidth} = window;

    if (innerWidth >= 768) {
        hideBackdrop(currentBackdrop);
    }
}

export function enableBodyScroll() {
    bodyEl.style.overflowY = "scroll";
}

export function disableBodyScroll() {
    bodyEl.style.overflowY = "hidden";
}

const languageSwitcher = () => {
    const languageCheckbox = document.querySelector(".language-switcher input");
    const toggleBtn = document.querySelector(".language-switcher label");
    const {href} = window.location;

    if (href.includes("/eng/")) {
        languageCheckbox.checked = true;
    }

    toggleBtn.addEventListener("click", () => {
        if (!languageCheckbox.checked) {
            window.location.href = `${href}eng/`;
        } else {
            window.location.href = href.replace("/eng/home-eng/", "/");
        }
    });
};

document.addEventListener("DOMContentLoaded", () => {
    bodyEl.style.visibility = "visible";
    new WOW().init();

    languageSwitcher();
});
